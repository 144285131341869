import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    visible: false,
    question: '',
    choix: {},
    choixSelected: ''
  },
  mutations: {
    setChoix(state, payload) {
      Vue.set(state, 'choix', payload.choix);
      Vue.set(state, 'question', payload.question);
      Vue.set(state, 'visible', true);
    },
    setChoixSelected(state, payload) {
      Vue.set(state, 'choixSelected', payload.selected);
    },
    unsetChoix(state) {
      Vue.set(state, 'visible', false);
      Vue.set(state, 'question', '');
      Vue.set(state, 'choix', {});
      Vue.set(state, 'choixSelected', '');
    },
    reset: (state) => {
      state.visible = false,
      state.question = '',
      state.choix = {},
      state.choixSelected = ''
    }
  },
  getters: {
    choixSelected: state => state.azerty
  },
  actions: {
    setChoix({commit}, payload) {
      commit('setChoix', payload);
    },
    setChoixSelected({commit}, payload) {
      commit('setChoixSelected', payload);
    },
    unsetChoix({commit}) {
      commit('unsetChoix');
    },
    reset: ({commit}) => {
      commit('reset');
    }
  }
};