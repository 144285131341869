<template>
  <v-dialog
    v-model="choixState"
    persistent
    max-width="500"
    :transition="false"
    scrollable
    v-if="choixState"
  >
    <v-card>
      <v-card-title class="v-card-title-info"
        ><h2 class="text-center" style="font-size:large">
          {{choixQuestion}}
        </h2></v-card-title
      >
      <v-card-text class="pa-5 text-center">
        <v-btn
          color="primary"
          dark
          @click="choose(choix.choix1.function)"
          class="mr-10"
        >
          {{choix.choix1.text}}
        </v-btn>
        <v-btn
          color="primary"
          dark
          @click="choose(choix.choix2.function)"
        >
          {{choix.choix2.text}}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ChoixModal",
  data() {
    return {};
  },

  methods: {
    unsetChoix() {
      this.$store.dispatch("choix/unsetChoix");
    },
    choose(functionName){
      this.$store.dispatch("choix/setChoixSelected", {selected:functionName});
    }
  },

  computed: {
    choix() {
      return this.$store.state.choix.choix;
    },

    choixQuestion() {
      return this.$store.state.choix.question;
    },

   choixState() {
      return this.$store.state.choix.visible;
    },
  },
};
</script>