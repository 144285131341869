<template>
  <div>
    <v-container style="margin-top: 10px ;">

      <!-- Documentation MILDIOU -->
        <div v-if="checkDroitLectureMildiou">
          <h3 style="margin-top:20px;" v-on:click="showButtonsMildiou = !showButtonsMildiou">
            Mildiou
            <v-icon small class="mr-2" v-if="!showButtonsMildiou">
              mdi-chevron-down
            </v-icon>
            <v-icon small class="mr-2" v-else>
              mdi-chevron-up
            </v-icon> 
          </h3>
      
          <div v-if="showButtonsMildiou"><!-- Les docs -->
            <v-btn small color="white" class="mt-2 white--text">
              <v-icon small class="mr-2"> mdi-file-document</v-icon>
              <a href="./documentation/VISIOFARM_Technicien_Mildiou_2023.pdf" download="./documentation/VISIOFARM_Technicien_Mildiou_2023.pdf"> Mode d'emploi </a>
            </v-btn>
            <br>
            <v-btn small color="white" class="mt-2 white--text">
              <v-icon small class="mr-2"> mdi-file-document</v-icon>
              <a href="./documentation/VISIOFARM_Technicien_Mildiou_2023.pdf" download="./documentation/Acces_Visiofarm_OAD.pdf"> Raccordement Visiofarm selon les OAD </a>
            </v-btn>
            <br>
            <v-btn small color="white" class="mt-2 white--text">
              <v-icon small class="mr-2"> mdi-file-document</v-icon>
              <a href="./documentation/202403_synchronisation_services_mildiou.pdf" download="./documentation/Synchronisation_services_mildiou.pdf"> Synchronisation des services complémentaires : Stations et Visiofarm </a>
            </v-btn>
            <div class="mt-15" style="max-width:600px;">
              Suivi des parcelles
              <vue-plyr>
                <div data-plyr-provider="youtube" data-plyr-embed-id="Q6JAkka75z4"></div>
              </vue-plyr>
            </div>
            
          </div>
        </div>
      <!-- FIN Documentation MILDIOU -->

      <!-- Documentation STATION -->
      <div v-if="checkDroitLectureMeteo">
          <h3 style="margin-top:20px;" v-on:click="showButtonsMeteo = !showButtonsMeteo">
            Station
            <v-icon small class="mr-2" v-if="!showButtonsMeteo">
              mdi-chevron-down
            </v-icon>
            <v-icon small class="mr-2" v-else>
              mdi-chevron-up
            </v-icon> 
          </h3>
      
          <div v-if="showButtonsMeteo"><!-- Les docs -->
            <div class="mt-5" style="max-width:600px;">
              Suivi des stations
              <vue-plyr>
                <div data-plyr-provider="youtube" data-plyr-embed-id="fBt2a0D5Ju4"></div>
              </vue-plyr>
            </div>
            
          </div>
        </div>
      <!-- FIN Documentation MILDIOU -->

      <!-- Documentation Stades -->
        <div v-if="checkDroitLectureStades">
          <h3 style="margin-top:20px;" v-on:click="showButtonsStade = !showButtonsStade">
            Stades et Maladies
            <v-icon small class="mr-2" v-if="!showButtonsStade">
              mdi-chevron-down
            </v-icon>
            <v-icon small class="mr-2" v-else>
              mdi-chevron-up
            </v-icon> 
          </h3>
          <div><!-- Les docs -->
            <v-btn v-if="showButtonsStade" small color="white" class="mt-2 white--text">
              <v-icon small class="mr-2"> mdi-file-document</v-icon>
              <a href="./documentation/Notice_utilisation_visiofarm_stade_maladie.pdf" download="./documentation/Notice_utilisation_visiofarm_stade_maladie.pdf"> Mode d'emploi </a>
            </v-btn>
          </div>
        </div>
      <!-- FIN Documentation Stades -->


    </v-container>
  </div>
</template>

<script>
import VuePlyr from 'vue-plyr'

export default {
  name: "helpDoc",
  components: {
    VuePlyr
  },
  data() {
    return {
      showButtonsMildiou: false, // Variable pour contrôler l'affichage des boutons
      showButtonsStade: false, // Variable pour contrôler l'affichage des boutons
      showButtonsMeteo: false
    };
  },
  computed: {
    currentRouteName() {
      console.log("currentRouteName", this.$route.name);
      return this.$route.name;
    },
    checkDroitLectureExterne() {
      return this.$store.getters['user/checkDroit']('EXTERNE','lecture');
    },
    checkDroitLectureMildiou() {
      return this.$store.getters['user/checkDroit']('MILDIOU','lecture');
    },
    checkDroitLectureADMIN() {
      return this.$store.getters['user/checkDroit']('ADMIN','lecture');
    },
    checkDroitLectureMeteo() {
      return this.$store.getters['user/checkDroit']('METEO','lecture');
    },
    checkDroitLectureCHN() {
      return this.$store.getters['user/checkDroit']('CHN','lecture');
    },
    checkDroitLectureBH() {
      return this.$store.getters['user/checkDroit']('BH','lecture');
    },
    checkDroitLectureCHN_SUP() {
      return this.$store.getters['user/checkDroit']('CHN_SUP','lecture');
    },
    checkDroitLectureStades() {
      return this.$store.getters['user/checkDroit']('STADES','lecture');
    },

  },
  watch: {
   
  },

  async mounted() {   
    
   
  },
  
  methods: {

  },

  };
  

</script>

<style scoped>
.chart {
  height: 450px;
}
.float {
  float: left;
  margin-right: 20px;
}

i{
  color: #087b7a !important;
}

/* @media only screen and (min-width: 0px) {
  .documents_container {
    border:4px solid yellow;
    margin-left:4rem;
  }
} */

</style>