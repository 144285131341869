<template>
  <v-container>
    <div class="mb-15">
      <v-row>
          <v-col>
            <v-switch
                v-model="demandesUtilisateur"
                label="Mes demandes"
                class="pa-3"
            ></v-switch>
          </v-col>
          <v-col>
            <v-text-field
              v-model="searchWord"
              append-icon="mdi-magnify"
              label="Recherche par mot clé"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :search="searchWord"
          sort-by="id"
          :sort-desc=true
          :loading="!dataloaded"
          loading-text="Chargement des données...."
          :headers="headers"
          :items="liste_demandes_recalage"
          item-key="id"
          class="my-awesome-table elevation-1"
          dense
          fixed-header
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon v-if="deletePossible(item)" small class="mr-2" @click="deleteItem(item)">
                mdi-delete
            </v-icon>
            <v-icon v-else small class="mr-2">
                mdi-circle-small
            </v-icon>
            <v-tooltip bottom max-width="350">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small :color="getColorValidation(item,'gestion')" class="mr-2" @click="validateRecalage(item,'gestion')" v-bind="attrs" v-on="on">
                  mdi-check
                </v-icon>
              </template>
              <span v-html="getDefinitionValidation(item,'gestion')"></span>
            </v-tooltip>
            <v-tooltip bottom max-width="350">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small :color="getColorValidation(item,'integration')" class="mr-2" @click="validateRecalage(item,'integration')" v-bind="attrs" v-on="on">
                  mdi-check-circle-outline
                </v-icon>
              </template>
              <span v-html="getDefinitionValidation(item,'integration')"></span>
            </v-tooltip>
            <v-icon small class="mr-2" @click="duplicateRecalage(item)">
              mdi-content-duplicate
            </v-icon>
            <v-icon small class="mr-2" @click="modifyRecalage(item)">
              mdi-pencil
            </v-icon>
          </template>

        </v-data-table>
      <v-btn small color="info" class="mt-2 mb-50 white--text">
          <download-excel :data="liste_demandes_recalage_tous" :fields="excel_fields" :name="getNomFichier()">
            Export Excel 
            <v-icon small class="mr-2">
              mdi-download
            </v-icon>
        </download-excel>
      </v-btn>
      <v-btn small color="info" class="mt-2 mr-2 mb-50 white--text float-right" @click="infosRecalage=null,sendDataFormRecalage()" :disabled='disabledRecalage'>
        <v-icon small class="mr-2">
          mdi-plus
        </v-icon>
        Nouvelle demande
      </v-btn>
    </div>

    <recalageFormComponent v-if="demandeRecalage" v-on:reloadRecalage="loadRecalage" :infosRecalage="infosRecalage" :key="componentKey"/>


    <h3 v-on:click="showRecalageServeur = !showRecalageServeur">
      Recalages en place
      <v-icon small class="mr-2" v-if="!showRecalageServeur">
        mdi-chevron-down
      </v-icon>
      <v-icon small class="mr-2" v-else>
        mdi-chevron-up
      </v-icon>
    </h3>

    <div v-if="showRecalageServeur" >

      <div>
        <v-row>
          <v-col cols="3">
            <v-file-input label="Fichier csv recalage" outlined dense v-model="fichierRecalage"></v-file-input>
          </v-col>
          <v-col cols="2">
            <v-btn small color="info" class="mt-2 mr-2 mb-50 white--text float-right" @click="readFichierRecalages()">
              Vérifier fichier
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div v-if="result_verifFichierRecalage.length!=0">
              <v-data-table
                :headers="headers_verifFichierRecalage"
                :items="result_verifFichierRecalage"
                item-key="line"
                dense
                fixed-header
                :hide-default-footer="true"
                disable-pagination
              />
            </div>
            <div v-if="verifFichierRecalage_fini==true && result_verifFichierRecalage.length==0">
              Le fichier est valide
            </div>
          </v-col>
          
        </v-row>
     </div>


      <div style="margin-top:20px;">
        <v-data-table
          :items="recalagesServeur"
          :headers="headersRecalagesServeur"
        />
      </div>

      <v-card style="padding:15px;margin-top: 20px;">
      Analyse des recalages en place
      <v-row>
        <v-col cols="3">
          <v-radio-group v-model="selectedTypeRecalage" row v-on:change="loadAnalyse([1,2])">
            <v-radio label="montaison" value="montaison"></v-radio>
            <v-radio label="epiaison" value="epiaison"></v-radio>
          </v-radio-group>
          <v-autocomplete
            v-model="selectedDepartement"
            :items="recalagesServeur"
            item-text="departements"
            item-value="departements"
            menu-props="auto"
            label="Departement(s)"
            chips
            dense
            v-on:change="loadAnalyse([1])"
          ></v-autocomplete>
          <v-autocomplete
            v-model="selectedDepartement2"
            :items="recalagesServeur"
            item-text="departements"
            item-value="departements"
            menu-props="auto"
            label="Departement(s)"
            chips
            dense
            v-on:change="loadAnalyse([2])"
          ></v-autocomplete>
        </v-col>
        <v-col>
          <div v-html="tableAnalyse"></div>
        </v-col>
        <v-col>
          <div v-html="tableAnalyse2"></div>
        </v-col>
      </v-row>
      </v-card>

      <recalageVerifComponent class="mt-15"/>
    </div>

    <!--CLEMENT-->
    <div v-if="checkDroitAdmin">
      <h3 v-on:click="showDerniersRecalages = !showDerniersRecalages">
        Affichage des recalages actifs des 7 derniers jours (admin)
        <v-icon small class="mr-2" v-if="!showDerniersRecalages">
          mdi-chevron-down
        </v-icon>
        <v-icon small class="mr-2" v-else>
          mdi-chevron-up
        </v-icon>
      </h3>
      <div v-if="showDerniersRecalages" >
        <v-row>
          <v-col cols="3" offset="1">
            <v-btn small color="info" style="padding:3px;"
              @click="launchAfficherDerniersRecalages()">
              <v-icon small class="mr-2">
                mdi-table-eye
              </v-icon>
              Afficher le tableau <br>
              des recalages actifs
            </v-btn>
            <br><br>
            <v-btn v-if="table_recalage_created" small color="info"
              @click="confirmSendMail()">
              <v-icon small class="mr-2">
                mdi-email-arrow-right-outline
              </v-icon>
              Envoyer les <br>rapports par mail
            </v-btn>
          </v-col>
          <v-col cols="5">
            <table id="table-recalage-histo">
              <!--FUTUR TAB de recalage des 7 derniers jours -->
            </table>
          </v-col>
        </v-row>
      </div>
    </div>
    <!--FIN Ajouts CC-->

  </v-container>
</template>

<script>
import recalageFormComponent from "@/components/stade/Recalage_Stade_Form.vue";
import recalageVerifComponent from "@/components/stade/Recalage_Stade_Verif.vue";
import downloadExcel from "vue-json-excel";
import recalageService from "@/service/recalage";
import { getDate, csvToJSON } from "@/helpers/functions";
//import VChart from "vue-echarts";

export default {
  name: "Recalage_Stade",
  components: {
    recalageFormComponent,
    recalageVerifComponent,
    downloadExcel,
    recalageService,
    getDate,
    csvToJSON
  },
  data() {
    return {
      login: this.$store.getters['user/login'],
      componentKey: 0,
      recalagesServeur: [],
      showRecalageServeur: false,
      showDerniersRecalages: false,
      tableAnalyse: '',
      tableAnalyse2: '',
      selectedDepartement: null,
      selectedDepartement2: null,
      selectedTypeRecalage: null,
      droits_recalage: null,
      disabledRecalage: true,
      demandeRecalage: false,
      demandesUtilisateur: true,
      liste_demandes_recalage: [],
      liste_demandes_recalage_tous: [],
      liste_demandes_recalage_utilisateur: [],
      dataloaded: false,
      searchWord: "",
      table_recalage_created : false,
      fichierRecalage: null,
      headers_verifFichierRecalage: [
        { text: 'Ligne', value: 'line'},
        { text: 'Errors', value: 'error'},
        { text: 'Warnings', value: 'warning'}
      ],
      result_verifFichierRecalage: [],
      verifFichierRecalage_fini: false,
      headers: [
        { text: "", value: "actions", sortable: false, width: '160px' },
        { text: 'Id', value: 'id'},
        { text: 'Culture', value: 'culture'},
        { text: 'Stade', value: 'stade'},
        { text: 'Départements', value: 'departements'},
        { text: 'Debut semis', value: 'datedebut', width:'100px'},
        { text: 'Fin semis', value: 'datefin', width:'100px'},
        { text: 'Stations', value: 'stations'},
        { text: 'Sols P.', value: 'typesolspanoramix'},
        { text: 'Sols Arvalis', value: 'typesolsarvalis'},
        { text: 'PRA', value: 'nom_pra'},
        { text: 'Demandeur', value: 'demandeur'},
        { text: 'Préco. montaison', value: 'precomontaison'},
        { text: 'Préco. épiaison', value: 'precoepiaison'},
        { text: 'Variétés', value: 'varietes'},
        { text: 'Décalage', value: 'jourdecalage'},
        { text: 'Type demande', value: 'typerecalage'},
        { text: 'Commentaire', value: 'commentaire', width:'250px'},
        { text: 'Date demande', value: 'date_demande'}
        ],
      headersRecalagesServeur: [
        { text: 'Année', value: 'annee'},
        { text: 'Espèce', value: 'espece'},
        { text: 'Décalage E1C', value: 'decalage_epi1cm'},
        { text: 'Décalage épaison', value: 'decalage_epiaison'},
        { text: 'Départements', value: 'departements'},
        { text: 'Date debut', value: 'debut_semis'},
        { text: 'Date fin', value: 'fin_semis'},
        { text: 'Montaison', value: 'montaison'},
        { text: 'Epiaison', value: 'epiaison'},
        { text: 'Type sol', value: 'type_sol'},
        { text: 'Code sol', value: 'code_sol'},
        { text: 'Station', value: 'station_meteo'},
        { text: 'PRA', value: 'PRA'},
        { text: 'Régional', value: 'regional'},
        { text: 'Commentaires', value: 'commentaires'}
      ],
      infosRecalage: null,
      excel_fields: {
        'ID':'id',
        'Type recalage':'typerecalage',
        'Ref recalage corrigé':'ref_recalagecorrige',
        'Stade':'stade',
        'Campagne':'campagne',
        'Date début':'datedebut',
        'Date fin':'datefin',
        'Type sol Panoramix':'typesolspanoramix',
        'Type sol Arvalis':'typesolsarvalis',
        'Départements':'departements',
        'Stations':'stations',
        'PRA':'pra',
        'PRA Nom':'nom_pra',
        'Culture':'culture',
        'Variétés':'varietes',
        'Variétés selon préco':'varietes_selon_preco',
        'Jour décalage':'jourdecalage',
        'Demandeur':'demandeur',
        'Date demande':'date_demande',
        'Préco montaison':'precomontaison',
        'Préco épiaison':'precoepiaison',
        'Commentaire':'commentaire',
        'Hors périmètre IR':'horsperimetre_ir'
      }
    };
  },
  async mounted() {
    this.loadRecalage()

    if((this.$store.getters['recalage/DroitsRecalage']())==null){
      this.droits_recalage = await recalageService.getDroitsRecalage();
      this.$store.commit('recalage/defineDroitsRecalage', {droits:this.droits_recalage})
    } else {
      this.droits_recalage = this.$store.getters['recalage/DroitsRecalage']()
    }

    if(this.droits_recalage.length!=0){
      this.disabledRecalage=false
    }

    this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
    this.recalagesServeur = await recalageService.recalageServeur();
    this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });

  },

  watch: {
    demandesUtilisateur: function(val) {
      if(val==true){
        this.liste_demandes_recalage = this.liste_demandes_recalage_utilisateur
      } else {
        this.liste_demandes_recalage = this.liste_demandes_recalage_tous
      }
    },
    choixSelected: function () {
      //appelle la function dont le nom est stocké dans le store
      if (this.choixSelected != "") {
        this[this.choixSelected]();
      }
    }
  },

  computed: {
    checkDroitAdmin(){
      return this.$store.getters['user/checkDroit']('ADMIN','lecture')
    },
    choixSelected() {
      return this.$store.state.choix.choixSelected;
    }
  },


  methods: {
    async loadRecalage(){
      this.liste_demandes_recalage_tous = await recalageService.getDemandesRecalageStades();
      this.dataloaded=true

      this.liste_demandes_recalage_utilisateur = this.liste_demandes_recalage_tous.filter(demande => demande.codeagent_demandeur == this.login);
      this.liste_demandes_recalage = this.liste_demandes_recalage_utilisateur

      this.demandeRecalage = false
    },
    duplicateRecalage(item){
      item.idRef=null
      this.infosRecalage=item
      this.sendDataFormRecalage(item)
    },
    modifyRecalage(item){
      item.idRef=item.id
      this.infosRecalage=item
      this.sendDataFormRecalage(item)
    },
    sendDataFormRecalage(){
      this.demandeRecalage=false
      this.componentKey += 1;
      this.demandeRecalage=true
    },
    getNomFichier(){
      var now = new Date()
      now = getDate(now,'yyyymmdd')

      return now+'_export_demandes_recalages'
    },
    async validateRecalage(item,type){
      if(this.checkDroitAdmin){
        var result = await recalageService.validationRecalageStade(item.id,type);
        if(result==true){
          this.$store.dispatch('informations/setInfo', { message: {titre:"Validation effectuée",description:"La validation a bien été enregistrée."} });
        } else {
          this.$store.dispatch('errors/setError', { message: {code:"",description:"Un problème est survenu."} });
        }
      }
    },
    getColorValidation(item,type){
      var color = 'red'
      if((item.validation_gestion==true && type=='gestion') || (item.validation_integration==true && type=='integration')){
        color = 'green'
      }

      return color
    },
    getDefinitionValidation(item,type){
      var text = ''
      if(item.validation_gestion==true && type=='gestion'){
        text = 'Recalage validé'
      } else if (item.validation_gestion==null && type=='gestion'){
        text = 'Recalage en attente de validation'
      } else if (item.validation_integration==true && type=='integration'){
        text = 'Recalage intégré'
      } else if (item.validation_integration==null && type=='integration'){
        text = 'Recalage en attente d\'intégration'
      }

      return text
    },
    deletePossible(item) {
      var result = false
      var delete_admin = ['danbik', 'kammob','godonl']
      if((item.codeagent_demandeur==this.login || delete_admin.includes(this.login)) && item.validation_gestion!==true){
        result = true
      }
      return result;
    },

    createTabDerniersRecalages(retour_data) {
      const table = document.getElementById("table-recalage-histo");

      if(retour_data.length==0){
        table.innerHTML = 'Aucun recalage récent';
      } else {
        let arr_obj_culture_dept = retour_data;
        const arr_data_culture_dept = arr_obj_culture_dept.map(obj => Object.values(obj));
    
        // Déclarations du contenu des cellules du tableau
          let cellText = '';

        //Nbr de lignes à construire
          const nbr_ligne = arr_data_culture_dept.length;
        //Nbr de cellules à construire
          const nbr_cell = arr_data_culture_dept[0].length;
        //Libellés des entêtes
          const libel_headers = Object.keys(arr_obj_culture_dept[0]);
        
        //Création des lignes
        for (let ligne = -1; ligne < nbr_ligne; ligne++) {
          const row = document.createElement("tr");
          
          //Création des cellules
          for (let col = 0; col < nbr_cell; col++) {
            const cell = document.createElement("td");

            if(ligne == -1 ){//Entête du tableau
              cell.classList.add("header_tab");
              if (col == 0){cellText = 'Code Culture';}
              if (col == 1){cellText = 'Code Departement';}
              if (col == 2){cellText = 'Libellé Culture';}

            } else if(ligne >-1){ // TT le Contenu du tableau
              cellText = document.createTextNode(arr_data_culture_dept[ligne][col]).nodeValue 
            }

            cell.appendChild(document.createTextNode(cellText))
            row.appendChild(cell);
          }
          table.appendChild(row);
          this.table_recalage_created = true;
          table.style.borderCollapse = "collapse";
        }
      }

    },

    async launchAfficherDerniersRecalages(){
      // Obtention des emails
      var data_reponse = await recalageService.listeDerniersRecalages();

      this.createTabDerniersRecalages(data_reponse);

    },

    async deleteItem(item){
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      var result = await recalageService.deleteRecalage(item.id);
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      if(result == true){
        this.$store.dispatch('informations/setInfo', { message: {titre:"Suppression",description:'Recalage supprimé'} });
        //actualisation tableau
        this.loadRecalage()
      } else {
        this.$store.dispatch('errors/setError', { message: {code:'',description:'Une erreur est survenue'} });
      }
    },

    get_prop(obj, prop) {
      return prop.split('.').reduce((o,k) => obj[k], obj);
    },

    coll2tbl(coll, row_header, col_header, cell) {
      var table = {};
      var row_headers = [];
      var cols = {};

      coll.forEach(a => {
          var h = this.get_prop(a, row_header);
          if (h in table === false) {
              table[h] = {};
              row_headers.push(h);
  }
          var c = this.get_prop(a, col_header);
          cols[c] = null;
          table[h][c] = this.get_prop(a, cell);

      });

      var cells = [];

      //on force un tableau avec pour chaque ligne et chaque colonne une valeur "-"
      row_headers.forEach(elementRow => {
        Object.keys(cols).forEach(elementCol => {
          if(table[elementRow][elementCol]==undefined){
            table[elementRow][elementCol] = '-'
          }
        })
      })

      //on rempli le tableau avec les bonnes valeurs lorsqu'il y en a
      var ligne = []
      row_headers.forEach(elementRow => {
        ligne = []
        Object.keys(cols).forEach(elementCol => {
          ligne.push(table[elementRow][elementCol])
        })
        cells.push(ligne)
      })

      return { row_headers, col_headers: Object.keys(cols), cells };
    },

    generateAnalyse(departements){

      var recalagesServeur = this.recalagesServeur
      var recalageDep = new Array()
      //pas possible de faire filter car utilise une shallow copy donc les modification sur le nouveau tableau ont des répercussions sur celui d'origine
      recalagesServeur.forEach(element => {
        if(element.departements==departements){
          recalageDep.push(element)
        }
      })


      var tabEspeces = [...new Set(recalageDep.map(item => item.espece))];
      var html = ''
      var recalageDepEspece = []
      
      tabEspeces.forEach(espece => {
        recalageDepEspece = []
        recalageDep.forEach(element => {
          if(element.espece==espece){
            recalageDepEspece.push(element)
          }
        })
        recalageDepEspece.sort(function(a, b){
          var aa = a.debut_semis.split('/').reverse().join(),
              bb = b.debut_semis.split('/').reverse().join();
          return aa < bb ? -1 : (aa > bb ? 1 : 0);
        });

        recalageDepEspece = recalageDepEspece.map(recalage => {
          recalage.plage_date = recalage.debut_semis+'-'+recalage.fin_semis;
          return recalage
        })

        //bascule de la colonne date en ligne
        var cell = null;
        if(this.selectedTypeRecalage == 'montaison'){
          cell = 'decalage_epi1cm'
        } else if(this.selectedTypeRecalage == 'epiaison'){
          cell = 'decalage_epiaison'
        }
        
        var table = this.coll2tbl(recalageDepEspece, 'plage_date', this.selectedTypeRecalage, cell);
        html += '<table class="analyseRecalage"><thead><tr><th>'+espece+'<br>'+departements+'</th>';
        table.col_headers.forEach(col_header => html += '<th>'+col_header+'</th>')
        html += '</tr></thead><tbody>'
        table.row_headers.forEach(function callback(row_header, index) {
          html += '<tr><td>'+row_header+'</td>'
          for (let pas = 0; pas < table.col_headers.length; pas++) {
            if(table.cells[index][pas]){
              html += '<td>'+table.cells[index][pas]+'</td>'
            } else {
              html += '<td></td>'
            }
          }
          html += '</tr>'
        })

        html += '</tbody></table><br><br>'

      });
      
      return html
    },

    loadAnalyse(num_table){
      num_table.forEach(num => {
        if (num==1 && this.selectedTypeRecalage!=null && this.selectedDepartement!=null){
          this.tableAnalyse = ''
          this.tableAnalyse = this.generateAnalyse(this.selectedDepartement)
        } else if (num==2 && this.selectedTypeRecalage!=null && this.selectedDepartement2!=null){
          this.tableAnalyse2 = ''
          this.tableAnalyse2 = this.generateAnalyse(this.selectedDepartement2)
        }
      })
    },

    closeChoix() {
      this.$store.dispatch("choix/unsetChoix");
    },

    async launchEnvoyerMail(){
      // Obtention des emails
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      var data_reponse = await recalageService.envoyerMail();
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      this.closeChoix()
    },

    confirmSendMail(){
      this.$store.dispatch("choix/setChoix", {
        question: "Etes-vous sûr de vouloir envoyer les mails ?",
        choix: {
          choix1: { text: "Oui", function: "launchEnvoyerMail" },
          choix2: { text: "Non", function: "closeChoix" },
        },
      });
    },

    readFichierRecalages(){
      this.verifFichierRecalage_fini = false
      if (window.FileReader) {  
        var reader = new FileReader();
        
        // Use the javascript reader object to load the contents
        // of the file in the v-model prop
        reader.readAsBinaryString(this.fichierRecalage);
        reader.onload = () => {
          //this.data = reader.result;
        }
        reader.onload = () => {

          var csv = reader.result;
          var parse_csv = csvToJSON(csv);
          this.verifFichierRecalages(JSON.stringify(parse_csv));
        
        }
        
        reader.onerror = function (evt) {
          if (evt.target.error.name == "NotReadableError") {
            alert("Le fichier ne peut pas être lu !");
          }
        };
      } else {
        alert('FileReader n\'est pas comptabible avec votre navigateur.');
      }
    },

    async verifFichierRecalages(json){
      var result = await recalageService.verifFichierRecalages(json);
      this.verifFichierRecalage_fini = true
      if(result.length!=0){
        this.result_verifFichierRecalage = result;
      }
    }

  }
};
</script>

<style>
.analyseRecalage{
  border-collapse: collapse;
}

.analyseRecalage thead > tr > th {
  padding: 5px;
  text-align: center;
  border: 1px solid black;
}

.analyseRecalage tbody > tr > td {
  padding: 5px;
  text-align: center;
  border: 1px solid black;
}
/* STYLE TABLEAU RECAP RECALAGE */
#table-recalage-histo th, #table-recalage-histo td {
  color:black;
  padding:0.5rem;
  text-align: center;
  border:1px solid black;
  border-collapse: collapse;
}

#table-recalage-histo tr:first-child , #table-recalage-histo td:first-child {
  font-size: 0.7rem;
  font-weight: bolder;
}

#table-recalage-histo .header_tab{
  background-color: rgb(153, 153, 153) !important ;
  border-bottom: 1px solid dimgray;
}


</style>
