import Equipment from '@/models/equipment/equipment';
import Technical_Features from '@/models/equipment/technical_features';
import Measured_Features from '@/models/equipment/measured_features';
import Maintenance from '@/models/maintenance/maintenance';
import Document from '@/models/equipment/document';

import store from '@/store/index';
import { handleServiceParams, errorDescription } from "@/helpers/serviceParamsHandler";

export default {
  async getVarietesCapNotes() {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}previRecalage/varietes/notes`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getAllStations() {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}previRecalage/stations`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getAllSols() {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}previRecalage/sols`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getAllPra() {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}previRecalage/pra`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getDroitsRecalage() {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}previRecalage/droits`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },


  async getDepartementsUtilisateur() {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}previRecalage/departements`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },


  async getDemandesRecalageStades() {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}previRecalage/demandes/stades`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },


  async envoyerRecalageStade(donneesRecalage) {
    const result = await
    fetch(`${process.env.VUE_APP_BASE_API}previRecalage/demandes/stades`, {
      method: 'POST',
      body: JSON.stringify(donneesRecalage),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then(response => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    }).then(json => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
    });
    return result;
  },


  async getDemandesRecalageMaladies() {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}previRecalage/demandes/maladies`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async envoyerRecalageMaladie(donneesRecalage) {
    const result = await
    fetch(`${process.env.VUE_APP_BASE_API}previRecalage/demandes/maladies`, {
      method: 'POST',
      body: JSON.stringify(donneesRecalage),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then(response => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    }).then(json => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
    });
    return result;
  },

  async validationRecalageStade(id,type) {
    var data = {id:id,type:type}
    const result = await
    fetch(`${process.env.VUE_APP_BASE_API}previRecalage/stades/validation`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then(response => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    }).then(json => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
    });
    return result;
  },

  async deleteRecalage(id) {
    // get a fully desribed parcelle from API
    // URL should be a composed string from Env constant and ressource name

    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}previRecalage/demandes/${id}/stades`, {
        method: 'DELETE',
        headers: {
          //'Content-Type': 'application/json',
          ///////Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async envoyerSimulation(donneesSimultation) {
    const result = await
    fetch(`${process.env.VUE_APP_BASE_API}previRecalage/simulation`, {
      method: 'POST',
      body: JSON.stringify(donneesSimultation),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then(response => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    }).then(json => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
    });
    return result;
  },

  async recalageServeur() {
    const local_dossier = await
    fetch(`${process.env.VUE_APP_BASE_API}previRecalage/serveur`, {
      method: 'GET',
      headers: {
        //'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        //Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((json) => {
      return json.data;
    })
      .catch((error) => {
        store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
      },
      );
  return local_dossier;
  },

  async envoyerMail() {
    const result = await
    fetch(`${process.env.VUE_APP_BASE_API}previRecalage/mail/rapport`, {
      method: 'GET',
      headers: {
        //'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
       // Accept: 'application/json',
      },
    }).then(response => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    }).then(json => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
    });
    return result;
  },

  async listeDerniersRecalages() {
    const result = await
    fetch(`${process.env.VUE_APP_BASE_API}previRecalage/recalages/derniers`, {
      method: 'GET',
      headers: {
        //'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
       // Accept: 'application/json',
      },
    }).then(response => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    }).then(json => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
    });
    return result;
  },

  async verifFichierRecalages(fichierRecalage) {
    var data = {csv:fichierRecalage}
    const result = await
    fetch(`${process.env.VUE_APP_BASE_API}previRecalage/stades/validationfichier`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then(response => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    }).then(json => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
    });
    return result;
  },

};